<template>
  <a-card style="background-color: #fff;">
    <div :class="advanced ? 'search' : null">
      <a-form layout="horizontal">
        <div :class="advanced ? null : 'fold'">
          <a-row style="display: flex;flex-wrap: wrap;">
            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-row>
                  <a-range-picker :value="dateValue" :ranges="{
      '今天': [moment(), moment()],
      '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      '当月': [moment().startOf('month'), moment().endOf('month')],
      '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }" style="width: 100%;" @change="dateChange" />
                </a-row>
              </a-form-item>
            </a-col>
            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="申请单号" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-input v-model="queryData.code" placeholder="请输入申请单号" />
              </a-form-item>
            </a-col>
            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="客户" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <!-- <a-select allowClear v-model="queryData.customerId" show-search
                  :filter-option="(input, option) => { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0; }"
                  style="width: 100%" placeholder="请选择客户" :default-value="null" option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData"
                    :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select> -->
                <a-select show-search :value="searchVal" placeholder="请输入客户名称" :default-active-first-option="false"
                  :show-arrow="false" :filter-option="false" :not-found-content="null" @search="fetchUser"
                  @change="handleSearchChange">
                  <a-select-option v-for="d in customerData" :key="d.name" :label="d.name" :value="d.id">
                    {{ d.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="客户经理" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select allowClear v-model="queryData.staffIdListParam" placeholder="请选择客户经理"
                  :filter-option="filterOption" :default-value="null" mode="multiple" option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in userData" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="申请状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select v-model="queryData.applyStatus" show-search
                  :filter-option="(input, option) => { return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0); }"
                  style="width: 100%" placeholder="请选择申请状态" :default-value="null" allowClear option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in statusList" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col style="width:33%;max-width:400px;">
              <a-form-item label="退票状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16, offset: 1 }">
                <a-select v-model="queryData.returnApplyStatus" show-search
                  :filter-option="(input, option) => { return (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0); }"
                  style="width: 100%" placeholder="请选择退票状态" :default-value="null" allowClear option-label-prop="label">
                  <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in returnStatusList"
                    :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col style="max-width:400px;">
              <span class="btn-box" style="margin-top: 4px; display: inline-block;">
                <a-col :md="12" :sm="20">
                  <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
                </a-col>
                <a-col :md="12" :sm="20">
                  <a-button type="primary" @click="search">查询</a-button>
                </a-col>
              </span>
            </a-col>
          </a-row>


        </div>
      </a-form>
      <a-row>
        <a-button @click="$router.push({ path: '/sfa_workOrder/addInvoiceApply' })" type="primary">发票申请</a-button>
      </a-row>
    </div>
    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{ queryData.size >
      total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>

    <div style="margin-top: 20px;">

      <a-table :columns="columns" :dataSource="dataSource" bordered :loading="tabLoading" :pagination="false"
        :rowKey="(record, index) => index" :scroll="{ x: 1500 }" class="components-table-demo-nested">
        <template slot="createTime" slot-scope="text">
          <span v-if="text">{{ formatDateMin(text) }}</span>
          <span v-else>-</span>
        </template>

        <template slot="saleDocketCode" slot-scope="text">
          <a-popover>
            <template slot="content">
              {{ text }}
            </template>
            {{ text }}
          </a-popover>
        </template>

        <template slot="invoiceUrl" slot-scope="text">
          <div>
            <p v-if="text"><a @click="downLoad(text)">下载发票</a></p>
            <p v-if="!text">未上传发票信息</p>
          </div>
        </template>

        <template slot="remarks" slot-scope="text">
          <div>
            <p v-if="text">{{ text }}</p>
            <p v-if="!text">暂无说明</p>
          </div>
        </template>



        <template slot="action" slot-scope="record">
          <div>
            <!-- // 1 6 撤回  4 5 作废  3 退票 -->
            <!-- //applyStatus 申请状态 1:开票申请中 2:开票中 3:开票完成 4:撤回 5:驳回 6:退票申请中 7:退票完成 8:部分开票 -->
            <!-- status 1正常 2作废 3退票 4换票 5预开票-->
            <!-- 状态不等于作废 并且 不等于退票完成 -->
            <a @click="detailsRecord(record.id)" style="margin-right: 5px;">详情</a>
            <span v-if="record.status != 2 && record.applyStatus != 7 && record.isShowBtn">
              <!-- <a @click="editRecord(record.id,record.invoicingType)" v-if="record.applyStatus == 4 || record.applyStatus == 5" style="margin-right: 5px;">编辑</a> -->
              <a @click="editRecord(record.id)" v-if="record.applyStatus == 4 || record.applyStatus == 5"
                style="margin-right: 5px;">编辑</a>
              <!-- 开票完成 -->
              <!-- <a @click="updateApplay(record,2)" v-if="record.applyStatus == 3 " style="margin-right: 5px;">作废</a> -->
              <!-- 开票申请中撤回 -->
              <a-popconfirm title="确认撤回吗" ok-text="是" cancel-text="否" @confirm="updateApplayStatus(record, 4)">
                <a v-if="record.applyStatus == 1" style="margin-right: 5px;">撤回申请</a>
              </a-popconfirm>
              <!-- 开票完成 退票申请中 撤回 -->
              <a-popconfirm title="确认撤回吗" ok-text="是" cancel-text="否" @confirm="updateReturnApplayStatus(record, 3)">
                <a v-if="record.returnApplyStatus == 1" style="margin-right: 5px;">撤回申请</a>
              </a-popconfirm>
              <!-- 开票完成 部分开票 退票 -->
              <!-- returnApplyStatus 1:退票申请中 2:退票完成 3:撤回 4:驳回 -->
              <!-- {{ record.returnApplyStatus }}--{{ record.applyStatus }} -->
              <a-popconfirm title="确认申请退票吗" ok-text="是" cancel-text="否" @confirm="updateReturnApplayStatus(record, 1)">
                <span v-if="record.returnApplyStatus != 1 && record.returnApplyStatus != 2">
                  <a v-if="record.applyStatus == 3 || record.returnApplyStatus == 3 || record.returnApplyStatus == 4"
                    style="margin-right: 5px;">申请退票</a>
                </span>
              </a-popconfirm>
              <a v-if="record.type == 2 && record.applyStatus == 3 && record.returnApplyStatus != 2"
                @click="updateChange(record)" style="margin-right: 5px;">关联</a>
            </span>
          </div>
        </template>
      </a-table>
      <div class="page-box">
        <a-pagination :total="total" :current="queryData.page + 1" :pageSize="queryData.size"
          @change="handlePageChange" />
      </div>
    </div>
  </a-card>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce';
export default {
  name: 'OutboundList',
  data() {
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 900);
    return {
      columns: [
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          width: 150,
          align: 'center',
        },
        // {
        //   title: 'code',
        //   dataIndex: 'saleDocketCode',
        //   ellipsis: true,
        //   scopedSlots: {customRender: 'saleDocketCode'},
        //   width:210,
        //   align: 'center',
        // },
        {
          title: '申请单号',
          dataIndex: 'code',
          ellipsis: true,
          scopedSlots: { customRender: 'code' },
          width: 210,
          align: 'center',
        },
        {
          title: '买方信息',
          dataIndex: 'customerName',
          align: 'center',
          width: 160
        },
        {
          title: '销售方信息',
          dataIndex: 'sellerName',
          align: 'center',
          width: 160
        },
        {
          title: '发票类型',
          dataIndex: 'invoiceTicketName',
          width: 180,
          align: 'center',
        },
        {
          title: '开票类型',
          dataIndex: 'typeName',
          width: 100,
          align: 'center',
        },
        {
          title: '开票金额',
          dataIndex: 'actualAmount',
          width: 100,
          align: 'center',
        },
        {
          title: '客户经理',
          dataIndex: 'staffName',
          width: 100,
          align: 'center',
        },
        // {
        //   title: '税率',
        //   dataIndex: 'taxRate',
        //   align: 'center',
        // },
        // {
        //   title: '类型',
        //   dataIndex: 'typeName',
        //   width:100,
        //   align: 'center',
        // },
        {
          title: '申请状态',
          dataIndex: 'applyStatusName',
          width: 120,
          align: 'center',
        },
        // {
        //   title: '申请单状态',
        //   dataIndex: 'statusName',
        //   align: 'center',
        //   width:120,
        // },
        {
          title: '退票状态',
          dataIndex: 'returnApplyStatusName',
          align: 'center',
          width: 120,
        },

        {
          title: '发票URL',
          dataIndex: 'invoiceUrl',
          width: 200,
          scopedSlots: { customRender: 'invoiceUrl' },
          align: 'center',
        },
        {
          title: '开票申请说明',
          dataIndex: 'applyExplain',
          scopedSlots: { customRender: 'applyExplain' },
          align: 'center',
          width: 180,
        },
        {
          title: '驳回原因',
          dataIndex: 'rejectReason',
          align: 'center',
          width: 180,
        },
        {
          title: '开票人',
          dataIndex: 'approvalStaffName',
          align: 'center',
          width: 180,
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
          align: 'left',
          width: 140
        },
      ],
      // 申请状态 1:开票申请中 2:开票中 3:开票完成 4:撤回 5:驳回 6:退票申请中 7:退票完成 8:部分开票s
      // statusList:[{id:1,name:'开票申请中'},{id:2,name:'开票中'},{id:3,name:'开票完成'},{id:4,name:'撤回'},{id:5,name:'驳回'},{id:6,name:'退票申请中'},{id:7,name:'退票完成'},{id:8,name:'部分开票'}],
      statusList: [{ id: 1, name: '开票申请中' }, { id: 3, name: '开票完成' }, { id: 4, name: '开票申请撤回' }, { id: 5, name: '开票申请驳回' }],
      returnStatusList: [{ id: 1, name: '退票申请中' }, { id: 2, name: '退票完成' }, { id: 3, name: '退票申请撤回' }, { id: 4, name: '退票申请驳回' }],
      iptmd: 5,
      iptsm: 22,
      coSpan: 6,
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      dataSource: [],
      tabLoading: false,
      total: 0,
      // 筛选
      queryData: {
        page: 0,
        size: 10,
      },
      searchVal: [],
      currentPage: 1,
      cardType: [{
        id: 1,
        name: '次票'
      }, {
        id: 3,
        name: '储值卡'
      }, {
        id: 4,
        name: '礼品兑换卡'
      }],
      distributorList: [],// 分销商
      sellerList: [],//卖方主体
      giftList: [],//卡券
      updValue: "",
      subValue: "",
      dateValue: "",
    }
  },
  watch: {
    $route() {
      this.getData();
      this.initData(); //初始化数据
    },
  },
  mounted() {
    this.getData();
    this.initData(); //初始化数据
  },
  computed: {
    ...mapGetters('account', ['user']),
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    },
    scrollX() {
      return this.columns.reduce((preVal, curVal) => {
        return preVal + curVal.width;
      }, 0);
    },

  },
  methods: {
    fetchUser(value) {
      if (!value) {
        return false;
      }
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerData = [];
      this.fetching = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/listByCustomerName', 'post', { page: 0, size: 10, name: value }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerData = res.data.data.data;
        this.fetching = false;
      }).catch(error => {
        console.error('Error fetching user:', error);
      });
    },
    handleSearchChange(searchVal) {

      this.queryData.customerId = searchVal;
      Object.assign(this, {
        searchVal,
        fetching: false,
      });
    },
    downLoad(text) {
      let arr = text.split(',').map(item => item.trim())
      console.log(arr, 'arar')
      arr.forEach(item => {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/download', 'post', { soundRecordingUrl: item }).then(res => {
          if (res.data) {
            // 创建一个新的窗口
            this.downFileNew(res.data)
            // const newWindow = window.open(res.data, '_blank');
            // if (newWindow) {
            //   newWindow.focus();
            // } else {
            //   // 如果新窗口被浏览器拦截，则提示用户
            //   this.$message.error('下载已被阻止，请检查浏览器设置');
            // }
          } else {
            this.$message.error(res.data);
          }
        });
      })
      // }
    },
    downFileNew(url) {
      let responses;
      fetch(url).then(response => {
        responses = response
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      }).then(blob => {
        // 获取文件类型
        const contentType = responses.headers.get('content-type');
        let extension = '.pdf'; // 默认为 PDF 文件

        if (contentType.includes('image/png')) {
          extension = '.png';
        } else if (contentType.includes('image/jpeg')) {
          extension = '.jpg';
        }
        // 创建一个临时的 URL 对象
        const url = window.URL.createObjectURL(blob);

        // 创建一个虚拟的链接元素，用于触发下载
        const link = document.createElement('a');
        link.href = url;
        link.download = `downloaded_file${extension}`; // 设置下载的文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // 释放 URL 对象
        window.URL.revokeObjectURL(url);
      })
        .catch(error => {
          console.error('文件下载失败', error);
        });
    },
    updateChange(record) {
      this.$router.push({ path: '/sfa_workOrder/addInvoiceApply', query: { id: record.id, type: 1, status: 'rel' } });
    },
    editRecord(id) {
      this.$router.push({ path: '/sfa_workOrder/addInvoiceApply', query: { id: id } });
    },
    detailsRecord(id) {
      this.$router.push({ path: '/sfa_workOrder/editInvoiceApply', query: { id: id, isDetail: true } });
    },
    updateApplay(record, status) {
      // 1 6 撤回      4 5 编辑   4 5 作废  3 退票
      // 申请状态 1:开票申请中 2:开票中 3:开票完成 4:撤回 5:驳回 6:退票申请中 7:退票完成 8:部分开票
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/updateStatus/' + record.id + '/' + status, 'post').then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！')
          this.getData()
        } else {
          this.$message.success(res.data.message);
        }
      })
    },
    updateApplayStatus(record, status) {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/updateApplayStatus/' + record.id + '/' + status, 'post').then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！')
          this.getData()
        } else {
          this.$message.success(res.data.message);
        }
      })
    },
    updateReturnApplayStatus(record, status) {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/updateReturnApplayStatus/' + record.id + '/' + status, 'post').then(res => {
        if (res.data.code == 200) {
          this.$message.success('申请成功！')
          this.getData()
        } else {
          this.$message.success(res.data.message);
        }
      })
    },
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];

      array.forEach((item, index) => {
        if (idMap[item.id] === undefined) {
          idMap[item.id] = index;
        } else {
          if (!duplicates.some(duplicate => duplicate.id === item.id)) {
            duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
          } else {
            const existingDuplicate = duplicates.find(duplicate => duplicate.id === item.id);
            existingDuplicate.indexes.push(index);
          }
        }
      });

      return duplicates;
    },
    codeChange(e) {
      this.giftList = []
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/gift/type/list/gift/' + e, 'get').then(res => {
        res.data.data.forEach(item => {
          item.giftList.forEach(it => {
            this.giftList.push(it)
          })
        })
      })
    },
    search() {
      this.queryData.page = 0;
      this.getData();
    },
    initData() {
      // 分销商
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/distributor/list', 'get').then(res => {
        this.distributorList = res.data.data;
      })
      // 卖方主体
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
        this.sellerList = res.data.data;
      })
      // 卡券
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/card/gift/type/list/gift/20220601001', 'get').then(res => {
        this.giftList = []
        res.data.data.forEach(item => {
          item.giftList.forEach(it => {
            this.giftList.push(it)
          })
        })
      })
      // 客户经理
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
        this.userData = res.data.data
      })
      // 客户列表
      // request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
      //   this.customerData = res.data.data
      // })
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },

    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    formatDateMin(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      return result;
    },
    // 选择日期
    dateChange(date, dateString) {
      this.queryData.dateMin = dateString[0].toString();
      this.queryData.dateMax = dateString[1].toString();
      this.dateValue = date;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
        // staffId:'',
        // dealStatus: '',//成交状态
        // type: '',//
      }
      this.subValue = ''
      this.dateValue = ''
      this.updValue = ''
      this.searchVal = []
      this.getData()
    },
    // 获取列表 
    getData() {
      this.tabLoading = true;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/invoiceapply/list', 'post', this.queryData).then(res => {
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = res.data.data.data;
          if (this.dataSource.length > 0) {
            this.dataSource.forEach(item => {
              if (item.staffId == this.$store.state.account.user.id) {
                item.isShowBtn = true;
              } else {
                item.isShowBtn = false;
              }
            })
          }
          this.queryData.page = res.data.data.page;
          this.queryData.size = res.data.data.size;
          this.total = res.data.data.total;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.EditVisible = false;
          this.getData()
        }
      })
    },

    remove() {
      this.dataSource = this.dataSource.filter(item => this.selectedRows.findIndex(row => row.key === item.key) === -1)
      this.selectedRows = []
    },
    onClear() {
      this.$message.info('您清空了勾选的所有行')
    },

  }
}
</script>

<style lang="less" scoped>
.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}

p {
  margin: 0;
}
</style>